<template>
  <div class="currency-page">
    <div class="currency container-fluid">
      <div class="container">
        <div class="currency-box">
          <div class="currency__title">
            <span>{{ $t('currency_page.title') }}</span>
          </div>
          <div class="bitcoin-box">
            <div class="box-item">
              <div class="item-image">
                <img src="./img/bitcoin.svg" alt="bitcoin" />
              </div>
              <div class="item-title">
                <span>{{ $t('currency_page.fit_spot') }}</span>
              </div>
              <div class="item-value">
                <span class="value">0.00004826</span>
                <span class="yellow-crypto">BTC</span>
              </div>
              <div class="item-price">
                <span>$2.88</span>
              </div>
            </div>
            <div class="box-item">
              <div class="item-image">
                <img src="./img/bitcoin.svg" alt="bitcoin" />
              </div>
              <div class="item-title">
                <span>{{ $t('currency_page.spot') }}</span>
              </div>
              <div class="item-value">
                <span class="value">0.00004826</span>
                <span class="yellow-crypto">BTC</span>
              </div>
              <div class="item-price">
                <span>$2.88</span>
              </div>
            </div>
            <div class="box-item">
              <div class="item-image">
                <img src="./img/bitcoin.svg" alt="bitcoin" />
              </div>
              <div class="item-title">
                <span>{{ $t('currency_page.fiat') }}</span>
              </div>
              <div class="item-value">
                <span class="value">0.0000000</span>
                <span class="yellow-crypto">BTC</span>
              </div>
              <div class="item-price">
                <span>$0.000000</span>
              </div>
            </div>
            <div class="box-item">
              <div class="item-image">
                <img src="./img/bitcoin.svg" alt="bitcoin" />
              </div>
              <div class="item-title">
                <span>{{ $t('currency_page.yesterday') }} PNL</span>
              </div>
              <div class="item-value">
                <span class="value">-$0.23</span>
                <span class="yellow-crypto">BTC</span>
              </div>
              <div class="item-price">
                <span class="red">-7.27%</span>
                <span> ></span>
              </div>
            </div>
          </div>
          <div class="balance-box">
            <div class="balance__title">
              <span>{{ $t('currency_page.details') }}</span>
            </div>
            <div class="balance__search">
              <div class="search">
                <input type="search" placeholder="Search coin" />
              </div>
              <div class="checkbox">
                <CheckBox />
              </div>
              <div class="text">
                <span>{{ $t('currency_page.convert_smal') }}</span>
              </div>
            </div>
            <div class="balance__table">
              <table>
                <thead>
                  <tr>
                    <th>{{ $t('currency_page.coin') }}</th>
                    <th>{{ $t('currency_page.total') }}</th>
                    <th>{{ $t('currency_page.available') }}</th>
                    <th>{{ $t('currency_page.order') }}</th>
                    <th>BTC {{ $t('currency_page.value') }}</th>
                    <th>{{ $t('currency_page.action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="coin-box">
                        <div class="color"></div>
                        <div class="btc">
                          <span>btc</span>
                          <span class="bnb">bnb</span>
                        </div>
                      </div>
                    </td>
                    <td>0.00382689</td>
                    <td>0.00382689</td>
                    <td>0.00000000</td>
                    <td>
                      <div class="btc-value">
                        <div class="value">
                          <span>0.00003633</span>
                          <span class="price">=$2.17</span>
                        </div>
                        <div></div>
                      </div>
                    </td>
                    <td>
                      <div class="yellow-text">
                        <span>{{ $t('currency_page.buy') }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="yellow-text">
                        <span>{{ $t('currency_page.deposit') }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="yellow-text">
                        <span>{{ $t('currency_page.withdraw') }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="yellow-text">
                        <span>{{ $t('currency_page.trade') }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="yellow-text">
                        <span>{{ $t('currency_page.ern') }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="yellow-text">
                        <span>{{ $t('currency_page.convert') }}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from '../../auth/signUp/CheckBox.vue'
export default {
  components: {
    CheckBox,
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
